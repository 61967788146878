<template>
    <div class="modal workout_modal" v-if="modelValue" :class="{ active: modelValue }">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Activity<span>{{ filter }}</span></h1>
                    <div class="user_info">
                        <img src="@/assets/images/male-female.png" :alt="contact.name">
                        <div>
                            <h5>{{ contact.name }}</h5>
                            <h6>{{ contact.email }}</h6>
                        </div>
                    </div>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
                <ul class="tab_sec">
                    <li :class="{ 'active': tab == 'workout' }" @click="tab = 'workout';">Workout</li>
                    <li :class="{ 'active': tab == 'notes' }" @click="tab = 'notes';">Notes</li>
                </ul>
            </div>
            <div class="modal_body">
                <div class="setting_wpr" v-if="contactWorkoutActivityLoader"><quote-loader /></div>
                <template v-else>
                    <div class="setting_wpr" v-if="tab == 'workout'">
                        <div class="slot_content" v-if="!viewWorkout">
                            <div class="toggle_content show">
                                <ul class="workout_card workout_list show">
                                    <li class="show">
                                        <div class="card_wrapper">
                                            <svg width="80" height="93" viewBox="0 0 80 93" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.9358 4.69088C18.9247 3.24462 32.2062 2.96341 38.4733 3.00358C57.371 3.00358 63.381 4.12845 64.0238 4.69088C72.3398 7.46286 76.2585 13.4901 76.9196 20.4792C77.7632 29.3978 71.7372 35.6649 69.6883 37.3522C68.2223 38.5595 68.9652 39.8429 69.6883 40.6063C75.1841 48.7053 76.3973 55.9526 76.317 58.5639C77.5222 72.0623 67.3984 83.2708 63.1801 86.4044C59.8055 88.9112 56.1497 89.4576 54.7437 89.4174C42.9326 90.5021 29.3136 89.5379 24.7338 89.4174C21.07 89.321 18.065 87.3685 17.0205 86.4044C7.49928 78.5705 3.16051 68.4467 3.76312 58.5639C4.36573 48.6812 9.34225 41.6842 10.1508 40.6063C10.8739 39.6421 12.0791 38.6779 10.3918 36.9906C7.37879 34.7007 2.00351 29.1567 3.16051 20.4792C4.60677 9.63226 12.1996 6.4987 15.9358 4.69088Z" stroke="#999999" stroke-width="5" />
                                                <path d="M17.8241 12.7621C12.3284 15.7511 11.2758 21.3192 11.4365 23.7296C11.7981 27.9478 15.5342 31.0814 16.9805 32.1661C18.1375 33.0338 19.7123 32.5276 20.3551 32.1661C26.8632 28.671 31.5636 27.4657 41.0848 27.3452C50.6059 27.2247 57.9578 31.684 59.5245 32.4071C60.778 32.9856 61.7341 32.6482 62.0555 32.4071C66.4907 29.4182 67.8405 25.3767 67.961 23.7296C68.5636 15.6547 60.7297 12.4006 60.0066 12.1595C59.4281 11.9667 57.8372 11.8381 57.1141 11.798C47.7134 11.1954 22.886 11.798 21.0782 11.798C19.632 11.798 18.3062 12.4408 17.8241 12.7621Z" stroke="#999999" stroke-width="5" />
                                                <path d="M32.1058 47.5454V72.9999H28.2528V51.3984H28.1036L22.0134 55.3757V51.6967L28.3646 47.5454H32.1058Z" fill="#858585" />
                                                <path d="M47.8533 73.4225C45.8895 73.4142 44.2116 72.8964 42.8195 71.8689C41.4275 70.8414 40.3627 69.3458 39.6253 67.382C38.8878 65.4183 38.5191 63.0526 38.5191 60.2851C38.5191 57.5259 38.8878 55.1685 39.6253 53.213C40.371 51.2575 41.4399 49.766 42.832 48.7386C44.2323 47.7111 45.9061 47.1974 47.8533 47.1974C49.8005 47.1974 51.4701 47.7153 52.8622 48.751C54.2542 49.7785 55.3189 51.2699 56.0564 53.2254C56.8021 55.1726 57.175 57.5259 57.175 60.2851C57.175 63.0609 56.8063 65.4307 56.0688 67.3945C55.3314 69.35 54.2666 70.8456 52.8746 71.8813C51.4825 72.9088 49.8088 73.4225 47.8533 73.4225ZM47.8533 70.104C49.5768 70.104 50.9232 69.263 51.8927 67.5809C52.8704 65.8989 53.3593 63.4669 53.3593 60.2851C53.3593 58.1722 53.1356 56.3865 52.6881 54.9282C52.249 53.4616 51.6151 52.3513 50.7865 51.5972C49.9662 50.8349 48.9885 50.4538 47.8533 50.4538C46.1381 50.4538 44.7916 51.2989 43.8139 52.9893C42.8361 54.6796 42.3431 57.1116 42.3348 60.2851C42.3348 62.4063 42.5544 64.2002 42.9935 65.6668C43.441 67.1252 44.0749 68.2314 44.8952 68.9854C45.7155 69.7311 46.7015 70.104 47.8533 70.104Z" fill="#858585" />
                                            </svg>
                                            <div class="info_wpr">
                                                <h4 @click="toggleDetails($event)">{{ workout.name  }}<span class="tag" v-if="contactWorkoutActivity.responses">{{ contactWorkoutActivity.responses ? moment(contactWorkoutActivity.responses.created_at).format('ll | LT') : '' }}</span><i class="fas fa-chevron-down"></i></h4>
                                                <div class="details" v-if="contactWorkoutActivity.responses && contactWorkoutActivity.responses.blocks">
                                                    <div class="blocks" v-for="(block, b) of contactWorkoutActivity.responses.blocks" :key="b">
                                                        <div class="block_title">
                                                            {{ block.name }}<span class="block_type">Perform as a {{ block.perform_type == 1 ? 'Progression' : 'Circuit' }}</span>
                                                            <h6 v-if="block.perform_type == 1"> 
                                                                {{block.circuit_settings.amrap ? 'AMRAP' : `${block.circuit_settings.round} rounds`}}
                                                                <div class="quick_info" v-if="block.circuit_settings.amrap">As Many Round As Possible</div>
                                                                <span v-if="block.circuit_settings.work_min || block.circuit_settings.work_sec">&nbsp;| {{block.circuit_settings.work_min ? `${block.circuit_settings.work_min} min` : ''}} {{block.circuit_settings.work_sec ? `${block.circuit_settings.work_sec} sec` : ''}} work </span>
                                                                <span v-if="block.circuit_settings.rest_min || block.circuit_settings.rest_sec">&nbsp;| {{block.circuit_settings.rest_min ? `${block.circuit_settings.rest_min} min` : ''}} {{block.circuit_settings.rest_sec ? `${block.circuit_settings.rest_sec} sec` : ''}} rest</span>
                                                                <span v-if="block.circuit_settings.amrap && block.circuit_settings.has_duration && (block.circuit_settings.block_duration_min || block.circuit_settings.block_duration_sec)">&nbsp;| {{ block.circuit_settings.block_duration_min ? `${block.circuit_settings.block_duration_min} min` : ''  }} {{ block.circuit_settings.block_duration_sec ? `${block.circuit_settings.block_duration_sec} sec` : ''  }}  duration</span>
                                                            </h6>
                                                        </div>
                                                        <div class="block_item border-0">
                                                            <div class="table_wpr">
                                                                <table class="task_history" v-if="block.perform_type == 2">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Work Time</th>
                                                                            <th>Load</th>
                                                                            <th>Rest Time</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <template v-for="(exercise, e) of block.exercises" :key="e">
                                                                            <tr>
                                                                                <td colspan="3" class="exercise_title" @click="toggleExercise($event)">
                                                                                    <div class="title">{{ exercise.name }} <i class="far fa-eye"></i></div>
                                                                                    <img :src="exercise.gif_image" :alt="exercise.name">
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>{{ exercise.work }}</td>
                                                                                <td>{{ exercise.is_bodyweight ? 'BW' : exercise.load }}</td>
                                                                                <td>{{ exercise.rest }}</td>
                                                                            </tr>
                                                                        </template>
                                                                    </tbody>
                                                                </table>
                                                                <table class="task_history" v-if="block.perform_type == 1">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Sets</th>
                                                                            <th>Reps</th>
                                                                            <th>Tempo</th>
                                                                            <th>Load</th>
                                                                            <th>Rest Time</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <template v-for="(exercise, e) of block.exercises" :key="e">
                                                                            <tr>
                                                                                <td colspan="5" class="exercise_title" :data-id="exercise.id" @click="toggleExercise($event)">
                                                                                    <div class="title">{{ exercise.name }} <i class="far fa-eye"></i></div>
                                                                                    <img :src="exercise.gif_image" :alt="exercise.name">
                                                                                    <table class="reps-table mb-3" :id="`reps-table-${exercise.id}`" v-if="block.perform_type == 1 && exercise.is_variable">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th class="set">Sets</th>
                                                                                                <th>Reps</th>
                                                                                                <th v-if="exercise.is_tempo">Tempo</th>
                                                                                                <th>Load</th>
                                                                                                <th>Rest Time</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <template v-if="exercise.response && exercise.response.set_settings">
                                                                                                <tr v-for="(set, s) in exercise.response.set_settings" :key="s">
                                                                                                    <td class="set">{{ s + 1 }}</td>
                                                                                                    <td>{{ set.reps }}</td>
                                                                                                    <td v-if="exercise.is_tempo">
                                                                                                        {{ set.tempo }}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div class="flex justify-content-center" v-if="exercise.load_unit_code.toLowerCase() !== '1rm'">
                                                                                                            {{ exercise.load_unit_code.toLowerCase() !== 'bw' ? set.load_number : '' }}
                                                                                                            &nbsp;{{ exercise.load_unit_code.toLowerCase()  !== 'bw' ? exercise.load_unit_code.toLowerCase() : 'Bodyweight' }}
                                                                                                        </div>
                                                                                                        <div class="flex justify-content-center" v-else>
                                                                                                            <template v-if="set.set_load">
                                                                                                                {{ oneRepMaxData[exercise.id] && oneRepMaxData[exercise.id].weight ? set.set_load : '' }}
                                                                                                            </template>
                                                                                                            <template v-else>
                                                                                                                {{ set.load_number }}
                                                                                                                <!-- {{ oneRepMaxData[exercise.id] && oneRepMaxData[exercise.id].weight ? Math.round(set.load_number * 0.01 * parseInt(oneRepMaxData[exercise.id].weight)) : '' }} -->
                                                                                                            </template>
                                                                                                            &nbsp;{{ set.set_load && oneRepMaxData[exercise.id] && oneRepMaxData[exercise.id].weight ? oneRepMaxData[exercise.id].unit : '% ' + exercise.load_unit_code.toLowerCase() }}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div class="flex justify-content-center">
                                                                                                            {{ set.rest_minutes }}:{{ set.rest_seconds }}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </template>
                                                                                            <template v-else>
                                                                                                <tr v-for="(set, s) in exercise.set_settings" :key="s">
                                                                                                    <td class="set">{{ s + 1 }}</td>
                                                                                                    <td>{{ set.reps }}</td>
                                                                                                    <td v-if="exercise.is_tempo">
                                                                                                        {{ set.tempo }}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div class="flex justify-content-center" v-if="exercise.load_unit_code.toLowerCase() !== '1rm'">
                                                                                                            {{ exercise.load_unit_code.toLowerCase() !== 'bw' ? set.load_number : '' }}
                                                                                                            &nbsp;{{ exercise.load_unit_code.toLowerCase()  !== 'bw' ? exercise.load_unit_code.toLowerCase() : 'Bodyweight' }}
                                                                                                        </div>
                                                                                                        <div class="flex justify-content-center" v-else>
                                                                                                            {{ set.set_load && oneRepMaxData[exercise.id] && oneRepMaxData[exercise.id].weight ? Math.round(set.load_number * 0.01 * parseInt(oneRepMaxData[exercise.id].weight)) : set.load_number }}
                                                                                                            &nbsp;{{ set.set_load && oneRepMaxData[exercise.id] && oneRepMaxData[exercise.id].weight ? oneRepMaxData[exercise.id].unit : '% ' + exercise.load_unit_code.toLowerCase() }}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div class="flex justify-content-center">
                                                                                                            {{ set.rest_minutes }}:{{ set.rest_seconds }}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </template>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="v_tag" v-if="block.perform_type == 1 && exercise.is_variable">V</span>
                                                                                    {{ exercise.sets }}
                                                                                </td>
                                                                                <td>
                                                                                    <span class="v_tag" v-if="block.perform_type == 1 && exercise.is_variable">V</span>
                                                                                    {{ exercise.response && exercise.response.exercise_reps ? exercise.response.exercise_reps : exercise.reps }}
                                                                                </td>
                                                                                <td>{{ exercise.response && exercise.response.tempo ? exercise.response.tempo : exercise.tempo }}</td>
                                                                                <td>{{ exercise.set_load ? exercise.set_load : exercise.load }}</td>
                                                                                <td>{{ exercise.rest }}</td>
                                                                            </tr>
                                                                        </template>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="feedback_btn" @click="toggleFeedback($event)">Show Feedback</div>
                                                    <div class="workout_reviews">
                                                        <div class="finish_timer">
                                                            <h3>Finished Time</h3>
                                                            <div class="total_timer">
                                                                <label>{{ contactWorkoutActivity.responses ? contactWorkoutActivity.responses.workout_hours : '' }}<span>hrs</span></label>:<label>{{ contactWorkoutActivity.responses.workout_minutes }}<span>min</span></label>:<label>{{ contactWorkoutActivity.responses.workout_seconds }}<span>sec</span></label>
                                                            </div>
                                                        </div>
                                                        <div class="reviews">
                                                            <h3>Overall Ratings</h3>
                                                            <ul>
                                                                <li><span>{{ contactWorkoutActivity.responses ? contactWorkoutActivity.responses.difficulty : '' }}</span>Difficulty</li>
                                                                <li><span>{{ contactWorkoutActivity.responses ? contactWorkoutActivity.responses.enjoyment : '' }}</span>Enjoyment</li>
                                                                <li><span>{{ contactWorkoutActivity.responses ? contactWorkoutActivity.responses.energy : '' }}</span>Energy</li>
                                                                <li><span>{{ contactWorkoutActivity.responses ? contactWorkoutActivity.responses.mood : '' }}</span>Mood</li>
                                                            </ul>
                                                        </div>
                                                        <div class="notes">
                                                            <h4>Notes:</h4>
                                                            <p>{{ contactWorkoutActivity.responses ? contactWorkoutActivity.responses.notes : '' }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="details" v-else>
                                                    <div class="blocks text-center mt-5">No response found</div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="history" v-if="contactWorkoutActivity.histories.length">
                                <h3 class="sub_header">History</h3>
                                <ul class="workout_card workout_list show">
                                    <li v-for="(history, h) of contactWorkoutActivity.histories" :key="h">
                                        <div class="card_wrapper">
                                            <svg width="80" height="93" viewBox="0 0 80 93" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.9358 4.69088C18.9247 3.24462 32.2062 2.96341 38.4733 3.00358C57.371 3.00358 63.381 4.12845 64.0238 4.69088C72.3398 7.46286 76.2585 13.4901 76.9196 20.4792C77.7632 29.3978 71.7372 35.6649 69.6883 37.3522C68.2223 38.5595 68.9652 39.8429 69.6883 40.6063C75.1841 48.7053 76.3973 55.9526 76.317 58.5639C77.5222 72.0623 67.3984 83.2708 63.1801 86.4044C59.8055 88.9112 56.1497 89.4576 54.7437 89.4174C42.9326 90.5021 29.3136 89.5379 24.7338 89.4174C21.07 89.321 18.065 87.3685 17.0205 86.4044C7.49928 78.5705 3.16051 68.4467 3.76312 58.5639C4.36573 48.6812 9.34225 41.6842 10.1508 40.6063C10.8739 39.6421 12.0791 38.6779 10.3918 36.9906C7.37879 34.7007 2.00351 29.1567 3.16051 20.4792C4.60677 9.63226 12.1996 6.4987 15.9358 4.69088Z" stroke="#999999" stroke-width="5" />
                                                <path d="M17.8241 12.7621C12.3284 15.7511 11.2758 21.3192 11.4365 23.7296C11.7981 27.9478 15.5342 31.0814 16.9805 32.1661C18.1375 33.0338 19.7123 32.5276 20.3551 32.1661C26.8632 28.671 31.5636 27.4657 41.0848 27.3452C50.6059 27.2247 57.9578 31.684 59.5245 32.4071C60.778 32.9856 61.7341 32.6482 62.0555 32.4071C66.4907 29.4182 67.8405 25.3767 67.961 23.7296C68.5636 15.6547 60.7297 12.4006 60.0066 12.1595C59.4281 11.9667 57.8372 11.8381 57.1141 11.798C47.7134 11.1954 22.886 11.798 21.0782 11.798C19.632 11.798 18.3062 12.4408 17.8241 12.7621Z" stroke="#999999" stroke-width="5" />
                                                <path d="M32.1058 47.5454V72.9999H28.2528V51.3984H28.1036L22.0134 55.3757V51.6967L28.3646 47.5454H32.1058Z" fill="#858585" />
                                                <path d="M47.8533 73.4225C45.8895 73.4142 44.2116 72.8964 42.8195 71.8689C41.4275 70.8414 40.3627 69.3458 39.6253 67.382C38.8878 65.4183 38.5191 63.0526 38.5191 60.2851C38.5191 57.5259 38.8878 55.1685 39.6253 53.213C40.371 51.2575 41.4399 49.766 42.832 48.7386C44.2323 47.7111 45.9061 47.1974 47.8533 47.1974C49.8005 47.1974 51.4701 47.7153 52.8622 48.751C54.2542 49.7785 55.3189 51.2699 56.0564 53.2254C56.8021 55.1726 57.175 57.5259 57.175 60.2851C57.175 63.0609 56.8063 65.4307 56.0688 67.3945C55.3314 69.35 54.2666 70.8456 52.8746 71.8813C51.4825 72.9088 49.8088 73.4225 47.8533 73.4225ZM47.8533 70.104C49.5768 70.104 50.9232 69.263 51.8927 67.5809C52.8704 65.8989 53.3593 63.4669 53.3593 60.2851C53.3593 58.1722 53.1356 56.3865 52.6881 54.9282C52.249 53.4616 51.6151 52.3513 50.7865 51.5972C49.9662 50.8349 48.9885 50.4538 47.8533 50.4538C46.1381 50.4538 44.7916 51.2989 43.8139 52.9893C42.8361 54.6796 42.3431 57.1116 42.3348 60.2851C42.3348 62.4063 42.5544 64.2002 42.9935 65.6668C43.441 67.1252 44.0749 68.2314 44.8952 68.9854C45.7155 69.7311 46.7015 70.104 47.8533 70.104Z" fill="#858585" />
                                            </svg>
                                            <div class="info_wpr">
                                                <h4 @click="toggleDetails($event)">{{ workout.name  }}<span class="tag">{{ moment(history.created_at).format('ll | LT') }}</span><i class="fas fa-chevron-down"></i></h4>
                                                <div class="details">
                                                    <div class="blocks" v-for="(block, b) of history.blocks" :key="b">
                                                        <div class="block_title">
                                                            {{ block.name }}<span class="block_type">Perform as a {{ block.perform_type == 1 ? 'Progression' : 'Circuit' }}</span>
                                                            <h6 v-if="block.perform_type == 1"> 
                                                                {{block.circuit_settings.amrap ? 'AMRAP' : `${block.circuit_settings.round} rounds`}}
                                                                <div class="quick_info" v-if="block.circuit_settings.amrap">As Many Round As Possible</div>
                                                                <span v-if="block.circuit_settings.work_min || block.circuit_settings.work_sec">&nbsp;| {{block.circuit_settings.work_min ? `${block.circuit_settings.work_min} min` : ''}} {{block.circuit_settings.work_sec ? `${block.circuit_settings.work_sec} sec` : ''}} work </span>
                                                                <span v-if="block.circuit_settings.rest_min || block.circuit_settings.rest_sec">&nbsp;| {{block.circuit_settings.rest_min ? `${block.circuit_settings.rest_min} min` : ''}} {{block.circuit_settings.rest_sec ? `${block.circuit_settings.rest_sec} sec` : ''}} rest</span>
                                                                <span v-if="block.circuit_settings.amrap && block.circuit_settings.has_duration && (block.circuit_settings.block_duration_min || block.circuit_settings.block_duration_sec)">&nbsp;| {{ block.circuit_settings.block_duration_min ? `${block.circuit_settings.block_duration_min} min` : ''  }} {{ block.circuit_settings.block_duration_sec ? `${block.circuit_settings.block_duration_sec} sec` : ''  }}  duration</span>
                                                            </h6>
                                                        </div>
                                                        <div class="block_item border-0">
                                                            <div class="table_wpr">
                                                                <table class="task_history" v-if="block.perform_type == 2">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Work Time</th>
                                                                            <th>Load</th>
                                                                            <th>Rest Time</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <template v-for="(exercise, e) of block.exercises" :key="e">
                                                                            <tr>
                                                                                <td colspan="3" class="exercise_title" @click="toggleExercise($event)">
                                                                                    <div class="title">{{ exercise.name }} <i class="far fa-eye"></i></div>
                                                                                    <img :src="exercise.gif_image" :alt="exercise.name">
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>{{ exercise.work }}</td>
                                                                                <td>{{ exercise.is_bodyweight ? 'BW' : exercise.load }}</td>
                                                                                <td>{{ exercise.rest }}</td>
                                                                            </tr>
                                                                        </template>
                                                                    </tbody>
                                                                </table>
                                                                <table class="task_history" v-if="block.perform_type == 1">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Sets</th>
                                                                            <th>Reps</th>
                                                                            <th>Tempo</th>
                                                                            <th>Load</th>
                                                                            <th>Rest Time</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <template v-for="(exercise, e) of block.exercises" :key="e">
                                                                            <tr>
                                                                                <td colspan="5" class="exercise_title" :data-history-id="`${exercise.id}-${h}`" @click="toggleExercise($event)">
                                                                                    <div class="title">{{ exercise.name }} <i class="far fa-eye"></i></div>
                                                                                    <img :src="exercise.gif_image" :alt="exercise.name">
                                                                                    <table class="reps-table mb-3" :id="`reps-history-table-${exercise.id}-${h}`" v-if="block.perform_type == 1 && exercise.is_variable">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th class="set">Sets</th>
                                                                                                <th>Reps</th>
                                                                                                <th v-if="exercise.is_tempo">Tempo</th>
                                                                                                <th>Load</th>
                                                                                                <th>Rest Time</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <template v-if="exercise.response && exercise.response.set_settings">
                                                                                                <tr v-for="(set, s) in exercise.response.set_settings" :key="s">
                                                                                                    <td class="set">{{ s + 1 }}</td>
                                                                                                    <td>{{ set.reps }}</td>
                                                                                                    <td v-if="exercise.is_tempo">
                                                                                                        {{ set.tempo }}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div class="flex justify-content-center" v-if="exercise.load_unit_code.toLowerCase() !== '1rm'">
                                                                                                            {{ exercise.load_unit_code.toLowerCase() !== 'bw' ? set.load_number : '' }}
                                                                                                            &nbsp;{{ exercise.load_unit_code.toLowerCase()  !== 'bw' ? exercise.load_unit_code.toLowerCase() : 'Bodyweight' }}
                                                                                                        </div>
                                                                                                        <div class="flex justify-content-center" v-else>
                                                                                                            <template v-if="set.set_load">
                                                                                                                {{ oneRepMaxData[exercise.id] && oneRepMaxData[exercise.id].weight ? set.set_load : '' }}
                                                                                                            </template>
                                                                                                            <template v-else>
                                                                                                                {{ set.load_number }}
                                                                                                                <!-- {{ oneRepMaxData[exercise.id] && oneRepMaxData[exercise.id].weight ? Math.round(set.load_number * 0.01 * parseInt(oneRepMaxData[exercise.id].weight)) : '' }} -->
                                                                                                            </template>
                                                                                                            &nbsp;{{ set.set_load && oneRepMaxData[exercise.id] && oneRepMaxData[exercise.id].weight ? oneRepMaxData[exercise.id].unit : '% ' + exercise.load_unit_code.toLowerCase() }}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div class="flex justify-content-center">
                                                                                                            {{ set.rest_minutes }}:{{ set.rest_seconds }}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </template>
                                                                                            <template v-else>
                                                                                                <tr v-for="(set, s) in exercise.set_settings" :key="s">
                                                                                                    <td class="set">{{ s + 1 }}</td>
                                                                                                    <td>{{ set.reps }}</td>
                                                                                                    <td v-if="exercise.is_tempo">
                                                                                                        {{ set.tempo }}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div class="flex justify-content-center" v-if="exercise.load_unit_code.toLowerCase() !== '1rm'">
                                                                                                            {{ exercise.load_unit_code.toLowerCase() !== 'bw' ? set.load_number : '' }}
                                                                                                            &nbsp;{{ exercise.load_unit_code.toLowerCase()  !== 'bw' ? exercise.load_unit_code.toLowerCase() : 'Bodyweight' }}
                                                                                                        </div>
                                                                                                        <div class="flex justify-content-center" v-else>
                                                                                                            {{ set.set_load && oneRepMaxData[exercise.id] && oneRepMaxData[exercise.id].weight ? Math.round(set.load_number * 0.01 * parseInt(oneRepMaxData[exercise.id].weight)) : set.load_number }}
                                                                                                            &nbsp;{{ set.set_load && oneRepMaxData[exercise.id] && oneRepMaxData[exercise.id].weight ? oneRepMaxData[exercise.id].unit : '% ' + exercise.load_unit_code.toLowerCase() }}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div class="flex justify-content-center">
                                                                                                            {{ set.rest_minutes }}:{{ set.rest_seconds }}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </template>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="v_tag" v-if="block.perform_type == 1 && exercise.is_variable">V</span>
                                                                                    {{ exercise.sets }}
                                                                                </td>
                                                                                <td>
                                                                                    <span class="v_tag" v-if="block.perform_type == 1 && exercise.is_variable">V</span>
                                                                                    {{ exercise.reps }}
                                                                                </td>
                                                                                <td>{{ exercise.tempo }}</td>
                                                                                <td>{{ exercise.set_load ? exercise.set_load : exercise.load }}</td>
                                                                                <td>{{ exercise.rest }}</td>
                                                                            </tr>
                                                                        </template>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="feedback_btn" @click="toggleFeedback($event)">Show Feedback</div>
                                                    <div class="workout_reviews">
                                                        <div class="finish_timer">
                                                            <h3>Finished Time</h3>
                                                            <div class="total_timer">
                                                                <label>{{ history ? history.workout_hours : '' }}<span>hrs</span></label>:<label>{{ history.workout_minutes }}<span>min</span></label>:<label>{{ history.workout_seconds }}<span>sec</span></label>
                                                            </div>
                                                        </div>
                                                        <div class="reviews">
                                                            <h3>Overall Ratings</h3>
                                                            <ul>
                                                                <li><span>{{ history ? history.difficulty : '' }}</span>Difficulty</li>
                                                                <li><span>{{ history ? history.enjoyment : '' }}</span>Enjoyment</li>
                                                                <li><span>{{ history ? history.energy : '' }}</span>Energy</li>
                                                                <li><span>{{ history ? history.mood : '' }}</span>Mood</li>
                                                            </ul>
                                                        </div>
                                                        <div class="notes">
                                                            <h4>Notes:</h4>
                                                            <p>{{ history ? history.notes : '' }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="setting_wpr" v-if="tab == 'notes'">
                        <div class="notes_list">
                            <h3 class="sub_header">Notes</h3>
                            <div class="search_area">
                                <input type="text" v-model="searchNotes" placeholder="Search by name, text etc...">
                                <button class="search_btn"><i class="fas fa-search"></i></button>
                            </div>
                            <ul>
                                <li v-for="(note, n) of notes" :key="n">
                                    <label>{{ moment(note.created_at).format('ll') }}</label>
                                    <div class="note">
                                        <q>{{ note.notes }}</q>
                                        <textarea placeholder="Reply with notes.." v-model="note.reply"></textarea>
                                    </div>
                                    <div class="action_area">
                                        <span class="timeline" v-if="note.is_replied">Replied on {{ moment(note.replied_at).format('ll') }}</span>
                                        <span class="reply_btn" v-if="!note.is_replied" @click="handleNoteReply($event, '', 2)">Reply <i class="fas fa-long-arrow-alt-right"></i></span>
                                        <span class="cancel_btn" @click="handleNoteReply($event)">Cancel</span>
                                        <span class="reply_btn send" @click="handleNoteReply($event, note, n)">Send via Portal Chat <i class="fas fa-long-arrow-alt-right"></i></span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import moment from 'moment-timezone'
import Swal from 'sweetalert2'
import Helper from '@/utils/Helper'

export default {
    name: 'Workout Activity',

    data() {
        return {
            contact: {},
            tab: 'workout',
            viewWorkout: false,
            searchNotes: '',
            month: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            date: new Date(),
            moment,
            notes: [],
            oneRepMaxData: [],
        };
    },

    props: {
        modelValue: Boolean,
        workout: Object,
        assignId: Number,
        contactId: Number,
        filter: String,
    },

    emit: ['update:modelValue'],

    watch: {
        modelValue (value) {
            const vm = this;

            vm.tab = 'workout';
            vm.toggleScrollBar(value);

            if (value) {
                vm.getContactWorkoutActivity({ workout: vm.workout.id, contact_id: vm.contactId, assign_id: vm.assignId });
            }
        },

        contactWorkoutActivity (activity) {
            const vm = this;
            const oneRepMaxData = [];

            vm.contact  = activity.contact;
            vm.notes    = activity.notes ? JSON.parse(JSON.stringify(activity.notes)) : [];

            if (activity.oneRepMaxData) {
                Object.values(activity.oneRepMaxData).forEach((load) => {
                    oneRepMaxData[load.exercise_assign_id] = load;
                });
            }

            vm.oneRepMaxData = oneRepMaxData;
        },

        searchNotes (search) {
            const vm    = this;
            const notes = JSON.parse(JSON.stringify(vm.contactWorkoutActivity.notes));

            if (search.length) {
                vm.notes = notes.filter(note => note.notes.toLowerCase().includes(search));
            } else {
                vm.notes = JSON.parse(JSON.stringify(vm.contactWorkoutActivity.notes));
            }
        },
    },

    computed: mapState({
        contactWorkoutActivity: state => state.workoutModule.contactWorkoutActivity,
        contactWorkoutActivityLoader: state => state.workoutModule.contactWorkoutActivityLoader,
    }),

    methods: {
        ...mapActions({
            getContactWorkoutActivity: 'workoutModule/getContactWorkoutActivity',
        }),

        closeModal () {
            const vm = this;

            vm.$emit('update:modelValue', false);
        },


        handleNoteReply (ev, note, number) {
            let el = ev.currentTarget.closest('li');

            if (el.classList.contains('active')) {
                // el.classList.remove('active');
            } else {
                // el.classList.add('active');
            }

            if (number && number >= 0) {
                const options = Helper.swalWarningOptions('Coming Soon!');

                Swal.fire(options);
            }
        },

        toggleDetails (ev) {
            const el = ev.currentTarget.closest('li');

            if (el.classList.contains('show')) {
                el.classList.remove('show');
            } else {
                el.classList.add('show');
            }
        },

        toggleFeedback (ev) {
            const el = ev.currentTarget;
            const nextEl = el.nextSibling;

            if (nextEl.classList.contains('show')) {
                nextEl.classList.remove('show');
                el.innerHTML = 'Show Feedback';
            } else {
                nextEl.classList.add('show');
                el.innerHTML = 'Hide Feedback';
            }
        },

        toggleExercise (ev) {
            const el        = ev.currentTarget;
            const id        = el.getAttribute('data-id');
            const historyId = el.getAttribute('data-history-id');

            if (el.classList.contains('show')) {
                el.classList.remove('show');
            } else {
                el.classList.add('show');
            }

            if (id) {
                const repEl = document.getElementById(`reps-table-${id}`);

                if (repEl.classList.contains('show')) {
                    repEl.classList.remove('show');
                } else {
                    repEl.classList.add('show');
                }
            }

            if (historyId) {
                const repEl = document.getElementById(`reps-history-table-${historyId}`);

                if (repEl.classList.contains('show')) {
                    repEl.classList.remove('show');
                } else {
                    repEl.classList.add('show');
                }
            }
        },
    }
}
</script>

<style scoped>
.preview_area {
    background: #fafafb;
}

.analytics_container {
    max-width: 1170px;
    padding: 0 20px;
    margin: 0 auto;
}

.preview_area .close_btn {
    right: 15px;
    top: 15px;
    left: auto;
    position: fixed;
    background: #eaeaea;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
}

.workout_title {
    display: flex;
    align-items: flex-end;
    gap: 10px;
    margin-top: 30px;
}

.workout_title h3 {
    font-size: 22px;
    line-height: 30px;
    color: #121525;
    font-weight: 500;
    text-align: left;
}

.workout_title h3 ul {
    display: flex;
    align-items: center;
    padding-bottom: 2px;
    gap: 10px;
}

.workout_title h3 ul li {
    font-size: 15px;
    line-height: 20px;
    color: #121525;
    font-weight: 500;
    padding-right: 10px;
}

.workout_title h3 ul li:not(:last-child) {
    border-right: 1px solid #d9d9d9;
}

.workout_title h3 ul li span {
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight: 400;
}

.workout_title .notes_btn {
    font-size: 13px;
    line-height: 25px;
    font-weight: 400;
    color: #2f7eed;
    cursor: pointer;
    margin-left: 10px;
}

.section_title {
    font-size: 20px;
    line-height: 26px;
    color: #121525;
    font-weight: 400;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 12px;
}

.section_title .Circuit,
.section_title .Progression {
    height: 20px;
    padding: 0 10px;
    border-radius: 12px;
    background: #eef5ff;
    border: 1px solid #c5ddff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #2f7eed;
}

/* .section_title .Progression{
    background: #fff8ec;
    border-color: #ffd283;
    color: #f2a31d;
} */
.section_title .filter,
.workout_title .filter {
    padding: 3px 0;
    margin-left: auto;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: #5a5a5a;
    position: relative;
    z-index: 3;
    cursor: pointer;
}

.section_title .filter .dropdown_wpr,
.workout_title .filter .dropdown_wpr {
    min-width: 160px;
    left: auto;
    right: 1px;
}

.section_title .filter .dropdown_wpr ul li,
.workout_title .filter .dropdown_wpr ul li {
    border: 0;
    text-align: left;
}

.section_title .filter i,
.workout_title .filter i {
    margin-left: 10px;
    color: #7a7a7a;
    pointer-events: none;
}

.analytics {
    display: flex;
    margin: 20px 0 40px 0;
    gap: 30px;
}

.analytics .analytics_card:first-of-type {
    flex: 0 0 355px;
}

.analytics_card {
    /* border: 1px solid #eaeaea; */
    background: #fff;
    border-radius: 8px;
    width: 100%;
    padding: 20px 20px;
    box-shadow: none;
    transition: all 0.3s ease-in-out;
}

.analytics_card h4 {
    font-size: 17px;
    line-height: 25px;
    color: #5a5a5a;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}

.analytics_card h4 .stat {
    font-size: 22px;
    line-height: 25px;
    color: #121525;
    font-weight: 500;
}

.analytics_card .analytic_info {
    display: flex;
    gap: 15px;
    margin-top: 15px;
}

.analytics_card .analytic_info.column {
    flex-direction: column;
}

.analytics_card .analytic_info li {
    flex: 1 1 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}

.analytics_card h5 {
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
}

.analytics_card h5 .stat {
    font-size: 20px;
    line-height: 25px;
    color: #121525;
    font-weight: 500;
}

.analytics_card label {
    font-size: 13px;
    line-height: 18px;
    color: #5a5a5a;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
}

.analytics_card label .time {
    font-size: 18px;
    line-height: 23px;
    color: #2f7eed;
    font-weight: 400;
    display: flex;
    align-items: flex-end;
}

.analytics_card label .time span {
    font-size: 13px;
    line-height: 16px;
    color: #121525;
    font-weight: 400;
    padding: 0 8px 2px 3px;
}

.analytics_card .reviews {
    margin: 10px 0 20px 0;
}

.analytics_card .reviews ul {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
}

.analytics_card .reviews ul li {
    flex: 1 1 auto;
    background: #f5f5f5;
    border-radius: 3px;
    padding: 10px;
    font-size: 11px;
    line-height: 14px;
    color: #121525;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
}

.analytics_card .ratings {
    display: flex;
    gap: 4px;
}

.analytics_card .ratings span {
    color: #2f7eed;
    font-size: 10px;
}

.analytics_card:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.analytics_card .score_circle {
    width: 40px;
    height: 40px;
    padding: 3px;
}

.analytics_card .score_circle .inner_circle {
    background: #fff;
}

.score_circle .inner_circle .score {
    font-size: 11px;
}

.analytics_modal .tab_row li.active {
    color: #2f7eed;
}

.analytics_modal .tab_row {
    padding: 20px 0 0 0;
    display: flex;
    gap: 30px;
}

.analytics_modal .tab_row li {
    padding-bottom: 12px;
    position: relative;
    cursor: pointer;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: #121525;
    overflow: hidden;
}

.analytics_modal .tab_row li:after {
    position: absolute;
    content: '';
    left: -100%;
    bottom: 0;
    width: 100%;
    border-bottom: 1px solid #2F80ED;
    transition: all 0.3s ease-in-out;
}

.analytics_modal .tab_row li.active {
    color: #2f7eed;
}

.analytics_modal .tab_row li.active:after {
    left: 0;
}

.leaderboard_wpr {
    margin: 15px 0 40px 0;
}

.show_hide {
    font-size: 11px;
    line-height: 14px;
    background: #fff;
    border: 1px solid #f5f5f5;
    padding: 7px 15px;
    border-radius: 15px;
    margin-bottom: 20px;
}

.leaderboard {
    border: 0;
}

.leaderboard .board_ttl {
    justify-content: flex-start;
    gap: 10px;
    line-height: 18px;
    border: 0;
    padding: 12px 20px 12px 15px;
}

.leaderboard .board_ttl i {
    color: #5a5a5a;
    cursor: pointer;
    margin-left: auto;
}

.leaderboard .board_ttl svg {
    height: 18px;
    width: auto;

}

.leaderboard ul {
    background: #fff;
    height: 200px;
}

.leaderboard ul li h5 {
    margin-right: auto;
}

.leaderboard ul li .streaks {
    font-size: 13px;
    line-height: 16px;
    color: #5a5a5a;
    font-weight: 400;
}

.leaderboard ul li .ratings {
    display: flex;
    gap: 1px;
}

.leaderboard ul li .ratings span {
    color: #2f7eed;
    font-size: 8px;
}

.leaderboard_slider {
    padding: 15px 20px 40px 20px;
    margin: 0 -20px
}

.leaderboard_slider :deep(.swiper-slide) {
    width: auto
}

.leaderboard_slider :deep(.leaderboard) {
    width: 355px;
}

.leaderboard_slider :deep(.leaderboard ul) {
    padding-top: 0;
    height: 210px;
}

.leaderboard_slider :deep(.leaderboard li.header) {
    display: flex;
    justify-content: flex-end;
    background: #fbfbfb;
    position: sticky;
    top: 0;
    z-index: 2;
}

.leaderboard_slider :deep(.leaderboard li.header span) {
    width: 40px;
    font-size: 10px;
    line-height: 14px;
    color: #121525;
    font-weight: 500;
    padding: 0 5px;
    display: flex;
    justify-content: center;
}

.leaderboard_slider :deep(.leaderboard li .marks) {
    display: flex;
    justify-content: flex-end;
}

.leaderboard_slider :deep(.leaderboard li .marks span) {
    width: 40px;
    padding: 0 5px;
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight: 400;
    display: flex;
    justify-content: center;
}

.leaderboard_slider :deep(.swiper-button-next.swiper-button-disabled),
.leaderboard_slider :deep(.swiper-button-prev.swiper-button-disabled) {
    opacity: 0;
}

/* .leaderboard_slider :deep(.swiper-button-next),
.leaderboard_slider :deep(.swiper-button-prev){
    width: 25px;
    height: 25px;
    background: #f2a31d;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
} */
.leaderboard_slider :deep(.swiper-button-next) {
    right: 0;
}

.leaderboard_slider :deep(.swiper-button-prev) {
    left: 0;
}

.leaderboard_slider :deep(.swiper-button-next:after),
.leaderboard_slider :deep(.swiper-button-prev:after) {
    font-size: 18px;
    font-weight: 600;
}

.result_wpr table td:first-child,
.result_wpr table th:first-child {
    width: auto;
    text-align: left;
    padding-left: 15px;
}

.result_wpr table td .tag {
    padding: 3px 10px;
    font-size: 11px;
    line-height: 13px;
    font-weight: 400;
    border-radius: 10px;
    background: #efefef;
    color: #121525;
}

.result_wpr table td button {
    color: #2f7eed;
}

.modal .modal_header .close_btn.left_out {
    position: absolute;
}

.modal.workout_modal .modal_body {
    padding-top: 15px;
    padding-bottom: 0;
    background: #fff;
}

.modal.workout_notes .modal_body {
    padding-top: 15px;
    background: #fff;
}

.modal.workout_modal .btn_list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: -5px;
}

.modal.workout_modal .btn_list li {
    font-size: 11px;
    line-height: 15px;
    color: #121525;
    padding: 5px 10px;
    white-space: nowrap;
    cursor: pointer;
}

.modal.workout_modal .modal_body .setting_wpr {
    height: 100%;
}

.workout_modal .slot_content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.workout_modal .slot_content .history {
    background: #fbfbfb;
    border-top: 1px solid #f9f9f9;
    margin: 0 -45px;
    padding: 0 45px;
    flex: 1 1 auto;
}

.workout_modal .slot_content .nav_bar {
    padding: 15px 45px;
    margin: 0 -45px;
    background: #fff;
    border-bottom: 1px solid #f5f5f5;
    position: sticky;
    top: 0;
    z-index: 2;
}

.workout_modal .slot_content .nav_bar .back_btn {
    font-size: 12px;
    font-weight: 15px;
    font-weight: 500;
    color: #5a5a5a;
    display: flex;
    align-items: center;
    gap: 7px;
    cursor: pointer;
}

.workout_modal .slot_content .toggle_content {
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

.workout_modal .slot_content .toggle_content.show {
    max-height: 100000px;
}

.workout_modal .slot_content .empty_text {
    font-size: 13px;
    line-height: 18px;
    font-weight: 300;
    color: #999;
    text-align: center;
    padding: 25px 0;
}

.workout_modal h3.sub_heading2 {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    padding: 30px 0 0;
    margin: 0;
}

.workout_modal h3.sub_heading2 i {
    color: #999;
    margin-left: auto;
    transform: rotate(0);
    transition: all 0.3s ease-in-out;
    display: none;
}

.workout_modal h3.sub_heading2.rotate i {
    transform: rotate(-180deg);
}

.workout_modal .workout_card {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    padding-bottom: 10px;
    transition: all 0.3s ease-in-out;
    border-bottom: 1px solid #e9e9e9;
}

.workout_modal .workout_card:last-of-type {
    border: 0;
    padding-bottom: 20px;
}

.workout_modal .workout_card li {
    display: flex;
    flex-direction: column;
}

.workout_modal .workout_card li a {
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: var(--var-calendar-event-cta-color, #2f7eed);
    margin: 0 0 0 auto;
    cursor: pointer;
}

.workout_modal .workout_card li .category_title {
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    color: #121525;
    margin: 0 auto 7px 0;
}

.workout_modal .workout_card li .card_wrapper {
    border-radius: 6px;
    display: flex;
    gap: 12px;
    cursor: pointer;
    padding: 20px 0 0 0;
}

.workout_modal .workout_card li .card_wrapper svg {
    flex: 0 0 18px;
    height: 18px;
}

.workout_modal .workout_card li .video_area {
    width: 25%;
    border-right: 1px solid #f5f5f5;
}

.workout_modal .workout_card li .video_area img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.workout_modal .workout_card li .info_wpr {
    border-bottom: 1px solid #e9e9e9;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding-bottom: 20px;
    gap: 0;
    transition: all 0.3s ease-in-out;
}

.workout_modal .workout_card li:last-child .info_wpr {
    border-bottom: 0;
}

.workout_modal .workout_card li .info_wpr h4 {
    font-size: 13px;
    line-height: 18px;
    color: #121525;
    font-weight: 500;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-right: 20px;
    gap: 7px;
    justify-content: space-between;
    position: relative;
}

.workout_modal .workout_card li .info_wpr span.tag {
    background: #ecf4ff;
    padding: 1px 6px;
    border-radius: 8px;
    font-size: 9px;
    line-height: 15px;
    font-weight: 500;
    color: #2f7eed;
}

.workout_modal .workout_card li .info_wpr h4 i {
    position: absolute;
    top: 5px;
    right: 0;
    font-size: 10px;
    color: #999;
    transition: all 0.3s ease-in-out;
}

.workout_modal .workout_list li .info_wpr h4 i {
    margin-left: 15px;
    pointer-events: none;
}

.workout_modal .workout_card li .info_wpr h6 {
    font-size: 11px;
    line-height: 15px;
    color: #5a5a5a;
    font-weight: 500;
    display: flex;
    flex-wrap: wrap;
    /* margin: 0 -5px 5px -5px; */
    gap: 5px;
}

.workout_modal .workout_card li .info_wpr h6 span {
    padding: 0 5px 0 0;
}

.workout_modal .workout_card li .info_wpr h6 span:not(:last-child) {
    border-right: 1px solid #d9d9d9;
}

.workout_modal .workout_card li .info_wpr .blocks {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 10px;
    flex: 0 0 100%;
}

.workout_modal .workout_card li .info_wpr .blocks .block_title {
    font-size: 13px;
    line-height: 18px;
    color: #121525;
    font-weight: 400;
}

.workout_modal .workout_card li .info_wpr .blocks .block_title .block_type {
    font-size: 9px;
    line-height: 12px;
    color: var(--var-calendar-event-tag-text-color, #2f7eed);
    font-weight: 500;
    margin-left: 10px;
    background: var(--var-calendar-event-tag-color, #ecf4ff);
    padding: 3px 8px;
    border-radius: 10px;
}

.workout_modal .workout_card li .info_wpr .blocks .block_title h6 {
    font-weight: 400;
    margin-top: 10px;
}

.workout_modal .workout_card li .info_wpr .block_item {
    border: 1px solid #f5f5f5;
    background: #fff;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

.workout_modal .workout_card li .info_wpr .block_item h5 {
    font-size: 11px;
    line-height: 16px;
    color: #121525;
    font-weight: 500;
}

.workout_modal .workout_card li .info_wpr .block_item h6 {
    font-size: 9px;
    line-height: 12px;
    color: #5a5a5a;
    font-weight: 400;
}

.workout_modal .workout_card li .info_wpr .block_item h6 .v_tag {
    width: 14px;
    height: 14px;
    background: #999;
    border: 1px solid #e9e9e9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    line-height: 10px;
    font-weight: 500;
    color: #fff;
    margin-right: 5px;
    border-radius: 50%;
    padding: 0;
}

.table_wpr {
    width: 406px;
    overflow-x: auto;
}

table.task_history {
    min-width: 350px;
    width: 100%;
    border-spacing: 1px;
    background: #eaeaea;
}

.task_history th,
.task_history td {
    padding: 10px 0;
    border-radius: 3px;
    text-align: center;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #121525;
}

.task_history thead tr {
    border-bottom: 1px solid #e9e9e9;
}

.task_history td.exercise_title {
    padding: 7px 15px;
    background: #fbfbfb;
    font-size: 11px;
    line-height: 16px;
    color: #121525;
    font-weight: 500;
    text-align: left;
    cursor: pointer;
}

.task_history td.exercise_title .title {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
}

.task_history td.exercise_title .title i {
    color: #5a5a5a;
}

.task_history td.exercise_title img {
    display: none;
}

.task_history td.exercise_title.show img {
    display: block;
}

.task_history td {
    width: 10%;
    background: #fff;
    color: #777;
}

.workout_modal .workout_card li .info_wpr .details {
    display: flex;
    flex-wrap: wrap;
    max-height: 0;
    overflow: hidden;
    gap: 15px;
    transition: all 0.3s ease-in-out;
}

.workout_modal .workout_card li .info_wpr .details .feedback_btn {
    background: #f5f5f5;
    padding: 4px 10px;
    border-radius: 11px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #121525;
    margin: 10px auto 0 auto;
}

.workout_modal .workout_card li .info_wpr .details .workout_reviews {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-height: 0;
    padding-bottom: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

.workout_modal .workout_card li .info_wpr .details .workout_reviews.show {
    max-height: 500px;
    padding-bottom: 15px;
    margin-top: 10px;
}

.workout_reviews .finish_timer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 0 15px;
}

.workout_reviews h3 {
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: #121525;
}

.workout_reviews .finish_timer .total_timer {
    display: flex;
    gap: 7px;
}

.workout_reviews .finish_timer .total_timer label {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    line-height: 25px;
    color: #121525;
    font-weight: 500;
}

.workout_reviews .finish_timer .total_timer label span {
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #5a5a5a;
}

.workout_reviews .reviews {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.workout_reviews .reviews ul {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
    width: 100%;
}

.workout_reviews .reviews ul li {
    flex: 1 1 auto;
    background: #f5f5f5;
    border-radius: 3px;
    padding: 10px;
    font-size: 9px;
    line-height: 12px;
    color: #121525;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.workout_reviews .reviews ul li span {
    font-size: 15px;
    line-height: 18px;
    color: #121525;
    font-weight: 500;
}

.workout_reviews .notes {
    background: #f5f5f5;
    padding: 10px 15px 15px 15px;
    border-radius: 5px;
}

.workout_reviews .notes h4 {
    font-size: 13px;
    line-height: 16px;
    color: #121525;
    font-weight: 500;
    margin-bottom: 5px;
}

.workout_reviews .notes p {
    font-size: 11px;
    line-height: 16px;
    color: #5a5a5a;
    font-weight: 400;
}

.workout_modal .workout_card li .info_wpr .details .footer_action {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.cloud-icon {
    background: #f0f6ff;
    padding: 1px 6px;
    border-radius: 8px;
    font-size: 9px;
    line-height: 15px;
    font-weight: 500;
    color: #2f7eed;
}

.cloud-icon.green {
    background: #effff2;
    color: #23993e;
}

.workout_modal .workout_card li .info_wpr .details p.already-submitted {
    font-size: 11px;
    line-height: 14px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: auto;
}

.workout_modal .workout_card li .info_wpr .details .redirect_btn {
    font-size: 11px;
    line-height: 14px;
    padding: 6px 12px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    margin: 0 0 0 auto;
    text-decoration: none;
}

.workout_modal .workout_card li .info_wpr .details .details_info {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    gap: 7px;
}

.workout_modal .workout_card li.show .info_wpr {
    gap: 15px;
}

.workout_modal .workout_card li.show .info_wpr h4 i {
    transform: rotate(-180deg);
}

.workout_modal .workout_card li.show .info_wpr .details {
    max-height: 10000px;
}

.workout_modal .cell {
    width: 405px;
    padding: 60px 15px 80px;
    border-radius: 40px;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
    position: relative;
    margin: 30px auto;
}

.workout_modal .cell:after,
.workout_modal .cell:before {
    position: absolute;
    content: "";
    width: 50px;
    background: #f2f2f2;
    left: 50%;
    transform: translateX(-50%);
}

.workout_modal .cell:before {
    height: 7px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    top: 25px;
}

.workout_modal .cell:after {
    height: 50px;
    border: 1px solid #d9d9d9;
    border-radius: 50%;
    bottom: 15px;
}

.workout_modal .cell .content_area {
    height: 610px;
    padding: 0;
    border: 1px solid #e7e7e7;
    background: #fafafb;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.workout_modal .cell .content_area::-webkit-scrollbar {
    display: none;
}

.notes_list .search_area {
    background: #f9f9f9;
    border-radius: 20px;
    padding-right: 25px;
    position: relative;
    margin-bottom: 20px;
}

.notes_list .search_area input {
    height: 32px;
    width: 100%;
    background: transparent;
    padding: 0 15px;
    font-size: 11px;
    font-weight: 400;
    color: #5a5a5a;
}

.notes_list .search_area .search_btn {
    position: absolute;
    right: 3px;
    top: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    font-size: 11px;
    color: #5a5a5a;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.notes_list ul {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-top: 10px;
}

.notes_list ul li {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.notes_list ul li label {
    font-size: 11px;
    line-height: 14px;
    color: #121525;
    font-weight: 500;
}

.notes_list ul li .note {
    background: #f5f5f5;
    border-radius: 5px;
    padding: 15px;
    line-height: 0;
}

.notes_list ul li .note .user {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 15px;
}

.notes_list ul li .note .user img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.notes_list ul li .note .user_info {
    font-size: 13px;
    line-height: 16px;
    color: #121525;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.notes_list ul li .note .user_info span {
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight: 400;
}

.notes_list ul li .note q {
    font-size: 13px;
    line-height: 20px;
    color: #5a5a5a;
    font-weight: 400;
    font-style: italic;
}

.notes_list ul li .note q:before,
.notes_list ul li .note q:after {
    font-size: 15px;
}

.notes_list ul li .note textarea {
    width: 100%;
    max-height: 0;
    height: 100px;
    overflow: hidden;
    padding: 0 15px;
    border-radius: 5px;
    background: #fff;
    font-size: 13px;
    line-height: 20px;
    color: #5a5a5a;
    font-weight: 400;
    resize: none;
    margin: 0;
    transition: all 0.3s ease-in-out;
}

.notes_list ul li .action_area {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.notes_list ul li .timeline {
    font-size: 11px;
    line-height: 14px;
    color: #999;
    font-weight: 400;
    font-style: italic;
    margin-right: auto;
}

.notes_list ul li .cancel_btn {
    font-size: 11px;
    line-height: 14px;
    color: #121525;
    font-weight: 500;
    cursor: pointer;
    padding-right: 10px;
    border-right: 1px solid #d9d9d9;
    display: none;
}

.notes_list ul li .reply_btn {
    font-size: 11px;
    line-height: 14px;
    color: #2f7eed;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.notes_list ul li .reply_btn.send {
    display: none;
}

.notes_list ul li.active .note textarea {
    max-height: 100px;
    padding: 10px 15px;
    margin: 10px 0 0 0;
}

.notes_list ul li.active .reply_btn:not(.send) {
    display: none;
}

.notes_list ul li.active .reply_btn.send,
.notes_list ul li.active .cancel_btn {
    display: block;
}

.reps-table {
    display: none;
}

.reps-table.show {
    display: block;
}

.task_history span.v_tag {
    background: #999;
    font-size: 8px;
    line-height: 10px;
    font-weight: 500;
    color: #fff;
    padding: 2px 4px;
    margin-right: 5px;
    border-radius: 50%;
}

@media(max-width: 767px) {
    .modal.analytics_modal .result_wpr .actions>ul>li.optionDrops {
        flex: 0 1 auto !important;
    }

    .analytics li {
        width: 33.333%;
    }

    .workout_modal .cell {
        transform: scale(0.7) translateX(-20%) translateY(-20%);
    }
}

@media(max-width: 499px) {
    .modal.analytics_modal .modal_container .modal_header {
        padding: 20px 20px 0 20px;
    }

    .modal.analytics_modal .modal_container .modal_body {
        padding: 0 20px;
    }

    .analytics li .analytics_card {
        padding: 15px 5px;
    }

    .analytics_card .score_circle {
        transform: none;
    }

    .analytics li .analytics_card h4 {
        margin-top: 7px !important;
    }
}

@media(max-width: 450px) {
    .result_wpr.new .actions>ul li.search_area {
        flex: 1 0 200px;
        width: auto;
    }
}
</style>